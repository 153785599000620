const questions = [
    {
        title: "What is metered mail?",
        description:
            "Metered mail is postage that does not need a traditional postage stamp as it is already paid in advance. A stamped ink marks proof of postage payment instead of a postage meter.",
    },
    {
        title: "Can I own a postage meter?",
        description:
            "Postage meters are used to print First-Class postage and other USPS services in the U.S that cannot be purchased by an individual or a business. This is because postage is considered a form of currency that needs regulation, like a money printing press.",
    },
    {
        title: "How can a postage meter help my business?",
        description:
            "Postage meters give your business access to discounted postage from the convenience of your office, thus saving you time and money. Such USPS postage rates can save you up to 5 cents on every First-Class letter sent and up to 30% off USPS Priority Mail Postage.",
    },
    {
        title: "What is a preferred postage rate?",
        description:
            "High-volume mailers are provided with 'bulk discount postage' and discounted postage for Priority Mail with a preferred rate, saving your business a significant amount of money over time. Preferred rates are best suited for businesses that ship large quantities of mail through the USPS.",
    },
];

export default questions;
