import { ReactElement } from "react";
import questions from "./questions";
import FeatureList from "@components/shared/featureList";

export default function FAQ(): ReactElement {
    return (
        <FeatureList
            featureList={questions}
            mainTitle="Frequently asked questions about postage meter machines"
            classes={{
                mainSectionClasses:
                    "flex flex-col hover:max-w-7xl mx-auto px-4vw lg:px-4 my-10 md:my-32",
                mainTitleClasses:
                    "font-bold capitalize mb-12 md:w-9/12 mx-auto md:mb-20 text-2xl text-center  md:text-4xl",
                innerSectionClasses: "grid grid-cols-1 md:grid-cols-2 gap-16",
                titleClasses: "text-xl md:text-2xl mb-2 font-semibold",
                descriptionClasses: "text-base md:text-xl font-light",
                oneBoxClasses: " ",
            }}
            colors={{
                descriptionColor: "#676767",
            }}
        />
    );
}
